import { useEffect, useState } from 'react';
import { 
  Datagrid,  
  List,  
  TextField,
  useRecordContext,
  useDataProvider,
  TextInput,
  SelectInput,
  usePermissions
   } from 'react-admin';

import { AddShortCreatorDialog } from './AddShortCreatorModal';

export const OwnShortList = () => {
  const dataProvider = useDataProvider();
  const {permissions} = usePermissions()
  const [ownChannels, setOwnChannels] = useState([])
  useEffect(()=>{
    if (typeof window.plausible === 'function') {
      const userEmail = localStorage.getItem('email')
      window.plausible('List Own Shorts', {props: {user: userEmail}})
    }
    (async () => {
      const result = await dataProvider.getList('ownShortChannels', {})
      const data = result.data.map(channel=>{return {id: channel.id, name: channel.name}})
      setOwnChannels(data)
      console.log(data)
    })()
  },[])

  return (
  <List filters={getFilters(ownChannels)} >
      <Datagrid bulkActionButtons={false}>
        <Thumbnail/>
        <Link source="title" label = 'Title'/>
        <TextField source='publish_date' label = 'Published' />
        <TextField source="views" />
        <TextField source="views_last_2h" />
        <TextField source="views_last_24h" label='Views last 24h' />
        <TextField source="retention_percentage" label='Retention' />
        <TextField source="retention_percentage_first_ten_seconds" label='Retention at 10 sec' />
        <TextField source="retention_first_thirty_seconds" label='Retention at 30 sec' />
        <TextField source="creator_names" label='Video Creators' sortable={false}/>
        {(permissions === 'superuser' || permissions === 'manager') && <AddVideoCreatorButton/> }
      </Datagrid>
  </List>
);
}

const getFilters = (ownChannels) => {
  const handleChange = (e)=>{
    const channel = ownChannels.find(channel=>channel.id === e.target.value)
    // if(!channel) return
    // localStorage.setItem('defaultChannel', JSON.stringify(channel))
    // setDefaultChannel(channel)
  }
return [
  <TextInput source="searchPhrase" label="Search" alwaysOn />,
  <SelectInput source ='Channel' choices= {ownChannels} alwaysOn onChange={(e)=> handleChange(e)}/>, 
]
}

const AddVideoCreatorButton = ()=> {
  const record = useRecordContext();
  if (!record) return <></>;
  return(
    <AddShortCreatorDialog  videoId={record['video_id']} ownedChannelsId = {record['owned_channel_id']} />
  )
}

const Thumbnail = () =>{
  const record = useRecordContext()
  if(!record) return null;
  let thumbnailHighRes = record['thumbnail_url'].replace('default','maxresdefault')
  return (
    <a href={thumbnailHighRes} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail_url']} alt ={record['title']} style = {{height:'6em'}}/>
    </a>
)
}
const Link = ({source}) =>{
  const record = useRecordContext()
  return record ? (
      <a href={'https://www.youtube.com/watch?v='+record['video_id']} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}